.hlello {
  width: 100%;
  min-height: 225px;
  background-color: #f9cfca;
  border: 15px solid #f9cfca;
  box-shadow: inset 0 0 0 2px #ffffff;
  position: relative;
}

.hlello h2 {
  color: #fff !important;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
}

.hlello h2 span {
  color: #fff;
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  line-height: 46px;
}

.about_section_home {
  background-color: var(--bgPitchColor);
}

.about_section_home p {
  font-weight: 600;
  margin-bottom: 10px;
}

.paragraph_div p {
  font-weight: normal;
  margin-bottom: 10px;
}

.about_section_home ol {
  padding-left: 20px;
}

.about_section_home ol li {
  margin-bottom: 10px;
}

.accordion:hover .accordion-item:hover .accordion-item-content,
.accordion .accordion-item--default .accordion-item-content {
  height: auto;
  transition: 0.25s;
}

.accordion-item-content,
.accordion:hover .accordion-item-content {
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
}

.accordion-item {
  background-color: #faf0ee;
  border-radius: 0px;
  outline: none;
  border: none;
  color: #777;
}

.accordion-item .accordion-button {
  background-color: #faf0ee;
  outline: none;
  border: none;
}

.accordion-item h5 {
  color: #777;
  font-size: 17px !important;
  font-weight: 500 !important;

}

.accordion-item-content p {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0;
  color: #777;
}

.what_makes_diffrent {
  background-color: var(--bgPitchColor);
  background-image: url(../../images/bottom-shape.png);
  background-repeat: repeat-x;
  background-position: bottom;
  bottom: 0;
}


@media only screen and (max-width: 1440px) {
  h2 {
    font-size: 24px;
  }

  .hlello h2 {
    font-size: 27px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {

  .inner_banner {
    height: 160px;
  }


  .hlello h2 {
    font-size: 24px;
  }

  .hlello h2 span {
    font-size: 24px;
  }

  h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .hlello {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .hlello h2 {
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
  }

  .hlello h2 span {
    color: #ed1651;
    font-size: 16px;
    line-height: 20px;
  }

  .about_section_home p {
    font-weight: 400;
    margin-bottom: 10px;
  }
  





}



@media only screen and (max-width: 500px) {

  .hlello {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .hlello h2 {
    font-size: 14px;
 margin-top: 0px;

  }

  .hlello h2 span {
    color: #ed1651;
    font-size: 18px;
    line-height: 36px;

  }

  .about_section_home p {
    font-weight: 400;
    margin-bottom: 10px;
  }


  

}
