@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Hurricane&display=swap');


:root {
  --pitchColor: #faf0ee;
  --bgPitchColor: #faf0ee;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0rem;

}

.h4, h4 {
  font-size: calc(1.1rem + .3vw);
}

.h1, h1 {
  font-size: calc(1rem + 1.5vw);
}


.h2, h2 {
  font-size: calc(1rem + 1.5vw);

}

.sticky-top {
  top: -1px !important;

}


.form-control:focus,
.form-select:focus {
  outline: none;
  box-shadow: none;
  border-color: inherit;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #777777;
  line-height: 1.2;
  background: #fff;
  font-size: 16px;
}
p {
  line-height: 1.4;
}
body * {
  color: inherit;
}
a,
a:hover,
input,
select,
button,
textarea,
input:focus,
select:focus,
button:focus,
textarea:focus,
*:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  color: #777777 !important;
}
.h5 {
  color: #777777 !important;
}

.inner_banner {
  height: 240px;
  position: relative;
  margin-top: 25px;
}

.inner_banner h2 {
  color: #ed1651 !important;
  font-size: 42px ;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.text-danger {
  color: #ed1651 !important;
}

p {
  color: #777777 !important;
  font-size: 16px;
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.pitch_bg {
  background-color: var(--bgPitchColor);
  color: #777777;
}

.about_section_home,
.pitch_bg h2 {
  font-family: "Montserrat", sans-serif;
}

.mybulletlist3 ul {
  list-style-type: none;
  padding: 0 !important;
}

.mybulletlist3 li {
  padding-left: 0px;
  margin: 0px;
  list-style-type: none;
  line-height: 24px;
  margin-bottom: 0px;
}

.mybulletlist3 ul li a {
  text-decoration: none;
  color: #333;
  transition: all 0.5s ease;
}

.mybulletlist3 ul li a:hover {
  text-decoration: none;
  color: #d10000;
  transition: all 0.5s ease;
}

.socialIcons {
  list-style: none;
  padding: 0;
  margin: 0;
}

.socialIcons li {
  display: inline-block;
  margin-right: 10px;
}

.socialIcons li:last-child {
  margin-right: 0;
}

.socialIcons a {
  display: block;
  width: 40px;
  height: 40px;
  padding: 10px;
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  background: #333;
}

.socialIcons svg {
  width: auto;
  height: 100%;
  display: block;
  fill: #fff;
  position: relative;
  z-index: 1;
  -webkit-transition: fill 0.3s ease;
  -moz-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.socialIcons a:before {
  width: 40px;
  height: 40px;
  border: 0 solid #fff;
  background: #ec2024;
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1.8);
  opacity: 0;
  z-index: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: none;
}

.socialIcons a:hover:before {
  transform: translate3d(-50%, -50%, 0) scale(1);
  opacity: 1;
}

.socialIcons a:hover svg {
  fill: #fff;
}

.pink_color {
  color: #ed1651;
  font-weight: bold;
}

.equal-height-row {
  display: flex;
}

.equal-height-col {
  display: flex;
  flex-direction: column;
}

.equal-height-col > .card {
  flex: 1;
}

.equal-height-row .card {
  background-color: #f9cfca;
  border: #f9cfca;
}

.equal-height-row .card .min-height {
  min-height: 100px;
}




.cloud-wrap ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

  .cloud-wrap ul li {
      float: left;
      margin: 0;
      padding: 0;
      line-height: 21px;
  }

      .cloud-wrap ul li::after {
          content: "|";
          margin: 0 5px;
          font-size: 14px;
      }

      .cloud-wrap ul li {
          text-decoration: none;
          font-size: 14px;
          display: inline-block;
          color: #7c6866;
      }



.whatsapp_icon{

  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99;
  cursor: pointer;
  width: 70px;
  height: 70px;
  padding: 0;
  transition: 0.3s ease;



}


.h3, h3 {
  font-size: calc(1rem + .6vw) !important;
}

@media only screen and (max-width: 1440px) {
  .inner_banner h2 {
    font-size: 30px;
    text-align: center;

  }
  .res-img {
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .inner_banner h2 {
    font-size: 27px;
    text-align: center;

  }
  .min-height {
    min-height:min-content !important
  }
}

@media only screen and (max-width: 600px) {
  .inner_banner {
    height: 200px;
  }

  .inner_banner h2 {
    font-size: 24px;
    font-weight: 600 !important;
    text-align: center;
  }


h5{
  font-size: 16px !important;
  font-weight: 400 !important;
 
}



.min-height {
  min-height:min-content !important
}

.hlello {
  min-height:min-content !important

}

.res-img {
  width: 150px;
}


}




@media (max-width: 768px) {
  .lg-close {
    /* Ensure it is visible and properly styled on smaller screens */
    display: block;
    /* Adjust size or position if needed */
  }
}

.lg-close {
  z-index: 1000; /* Ensure this is higher than other elements */
}