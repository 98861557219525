
.swiper-container {
    height: 100%;
  }

  .swiper-slide img{
    max-width: 100px;
    margin: auto;
    margin-bottom: 10px;
  }

  .swiper-slide {
    text-align: center;
    background: url(../../images/testimonials-pinkBG.jpg);
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
   /* width: 70% !important; */
   padding: 18px;
   margin-bottom: 40px;


  }


.swiper-button-prev,
.swiper-button-next {
  color: #333 !important;
}



  
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, -10px) !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, -10px) !important;
}


.swiper-button-prev, .swiper-button-next {
    width: calc(var(--swiper-navigation-size) / 24 * 27) !important;
}





.scroll_swier{
    height: 145px;
    overflow-y: scroll;
    padding-right: 10px;
}