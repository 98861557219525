.testimonialsBG {
  text-align: center;
  background: url(../../images/testimonials-pinkBG.jpg);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* width: 70% !important; */
  padding: 18px;
  margin-bottom: 40px;
}

.testimonialsBG img {
  max-width: 100px;
  margin: auto;
}

.snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 9999;
  background: transparent;
}

.snow:before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://cdn.pixabay.com/photo/2017/11/07/09/56/snowflakes-2923875_960_720.png")
    repeat;
  opacity: 0.5;
  animation: snow 10s linear infinite;
}

@keyframes snow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

.modal-content {
  background: url(../../images/testimonials-pinkBG.jpg);
  background-size: cover;
  padding: 20px;
}

.wrapper-card {
  display: flex;
  align-items: stretch;
}

.scroll_swier {
  height: 140px;
  overflow-y: scroll;
  
}



.scroll_swier::-webkit-scrollbar-track
{
  	background-color: #F5F5F5;
}

.scroll_swier::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.scroll_swier::-webkit-scrollbar-thumb
{
	background-color: #bdbdbd;
	border: 2px solid #bdbdbd;
}



.redColor {
  color: #ed1651 !important;
  
  font-weight: 700;
}


.stylefont{
  font-family: "Grey Qo", cursive;
  font-weight: 500;
  font-size: 30px;
}

.stylefont1{
  font-family: "Grey Qo", cursive;
  font-weight: 500;

}



