
.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 30px;
    margin-top: 25px;
  }
  
  
  .vid-list .thumbnail {
    width: 100%;
  }
  
  .vid-list .flex-div {
    align-items: flex-start;
    margin-top: 7px;
  }
  
  .vid-list .flex-div img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .vid-info p {
    color: #5a5a5a;
    font-size: 14px;
    text-decoration: none;
  }
  
  
  
/* Flashing */
 .thumbnail:hover  {
	opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}



.thumbnail:hover .underline  {
display: block;

}


@-webkit-keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}