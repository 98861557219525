.uldiv  {
    text-align: left;
    width: 270px;
    float: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 15px 5%;
    height: 195px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
  }