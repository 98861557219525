.navbar::before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 20px;
  background-image: url(images/navbar_bottom_strip.png);
  background-repeat: repeat-x;
  z-index: 10;
}

.navbar-brand img {
  width: 120px;
}

.navbar-brand{
  position: relative;
  width: auto;
}


.navbar-brand::after {
  content: "";
  position: absolute;
  bottom: -64px;
  width: 200px;
  height: 80px;
  background-image: url(images/Wedding-Stories.svg);
  background-size: calc(100%);
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto 0px;
  z-index: 10;
  left: -40px;

}



.navbar-nav {
  align-items: center;
}

.nav_bar_bg {
  background-color: #f9cfca;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(255, 255, 255);
}

.navbar .navbar-nav .nav-link {
  font-family: "Montserrat", sans-serif;
  color: #777;
  font-size: 16px;
  padding: 0.5em 10px;
  margin-right: 2px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #fff;
  background-color: #ed1651;
  border-radius: 4px;
}

.navbar-nav .nav-link:hover {
  background-color: #ed1651;
  color: #ffffff;
  border-radius: 4px;
}

.dropdown-menu {
  top: 98% !important;
  min-width: 200px;
}

.dropdown:hover .nav-link {
  background-color: #ed1651;
  color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none;
}

.dropdown-item:active {
  background-color: #ed1651;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ed1651 !important;
  border-radius: 0;
}

@media only screen and (max-width: 1920px) {
  .navbar .navbar-nav .nav-link {
    font-size: 15px;
    padding: 0.5em 14px;
    margin-right: 0;
  }


  

}



@media only screen and (max-width: 1768px) {
  .navbar .navbar-nav .nav-link {
    font-size: 15px;
    padding: 0.5em 10px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1680px) {
  .navbar .navbar-nav .nav-link {
    font-size: 15px !important;
    padding: 0.5em 10px;
    margin-right: 0;
  }


  .navbar-brand img {
    width: 120px;
  }


  .navbar-brand::after {
    left: -40px;
  
  }

}


@media only screen and (max-width: 1527px) {
  .navbar .navbar-nav .nav-link {
    font-size: 14px !important;
    padding: 0.5em 8px;
    margin-right: 0;
  }

  .navbar-brand img {
    width: 120px;
  }

  .navbar-brand::after {
    left: -40px;
  
  }
  



}



@media only screen and (max-width: 1480px) {
  .navbar .navbar-nav .nav-link {
    font-size: 14px !important;
    padding: 0.5em 8px;
    margin-right: 0;
  }

  .navbar-brand img {
    width: 100px;
  }


  .dropdown-item {
   font-size: 14px;
}


.navbar-brand::after {

  left: -52px;

}




}



@media only screen and (max-width: 1366px) {
  .navbar .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 0.5em 8px;
    margin-right: 0;
  }

  .navbar-brand img {
    width: 100px;
  }


  .dropdown-item {
   font-size: 14px;
}

.navbar-brand::after {
  left: -52px;

}

}





@media only screen and (max-width: 1280px) {
  .navbar .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 0.5em 6px;
  
  }


  .navbar-brand img {
    width: 100px;
  }


}

@media only screen and (max-width: 1240px) {

  .navbar .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 0.5em 4px;
  
  }


  .navbar-brand img {
    width: 100px;
  }
  .navbar-brand::after {
    left: -50px;
  }

}


@media only screen and (max-width: 1024px) {


  .navbar-nav {
    align-items: flex-start !important;
  }

  .nav-link {
    width: 100%;
    padding-left: 10px;
  }

  .dropdown,
  .dropdown-center,
  .dropend,
  .dropstart,
  .dropup,
  .dropup-center {
    width: 100% !important;
    display: block;
  }



  .navbar-brand::after {
display: none;
  }
  
  .navbar-brand img {
    width: 150px;
  }


}


@media only screen and (max-width: 960px) {
  .navbar-brand img {
    width: 300px;
  }

}


@media only screen and (max-width: 768px) {
  .navbar-nav {
    align-items: flex-start !important;
  }

  .offcanvas {
    max-width: 60%;

    /* transition: var(--bs-offcanvas-transition); */
  }

  .nav-link {
    width: 100% !important;
    display: block;
  }

  .dropdown,
  .dropdown-center,
  .dropend,
  .dropstart,
  .dropup,
  .dropup-center {
    width: 100% !important;
    display: block;
  }

  .dropdown-item {
    font-size: 14px;
 }
}



@media only screen and (max-width: 411px) {
  .navbar-brand img {
    width: 220px;
  }

}