.servicesinsidenew {
  width: 100%;
  display: table;
  margin: 0 auto 55px;
  padding: 0;
}

.servicesinsidenew li {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flip-container-main:hover .flipper,
.flip-container.hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 324px;
  height: 250px;
  float: left;
}

.flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  float: left;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  background: #faf0ee;
}

.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: #f9cfca;
}

.back ul {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.back ul li {
  margin: 0;
  padding: 0;
  background: url("../../images/bullet-icon.png") no-repeat 6px 7px; /* Specify the image URL */
  background-size: 8px; /* Adjust the size of the image */
  padding-left: 25px;
  margin-bottom: 5px;
  line-height: 21px;
  color: #777777;
}

.front .name {
  font-size: 20px;
  display: inline-block;
  padding: 5px 10%;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding-top: 170px;
  line-height: 25px;
}

.front-icon {
  position: absolute;
  top: 50px;
  left: 100px;
  width: 120px;
}

.front-icon img {
  width: 120px;
}

.border334 {
  width: 90%;
  float: left;
  height: 87%;
  border-radius: 8px;
  margin: 5%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding-top: 10px;
}

.back p {
  text-align: center;
  width: 270px;
  float: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 15px 5%;
  height: 195px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
}


.back ul {
  text-align: left;
  width: 270px;
  float: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 15px 5%;
  height: 195px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
}





.hoverimage {
  width: 324px;
  height: 250px;
  float: left;
}

.hoverimage img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1366px) {


  .front .name {
    font-size: 14px;
    line-height: 18px;
}




  .flip-container,
  .front,
  .back {
    width: 275px;
  }

  .hoverimage {
    width: 275px;
    height: 250px;
    float: left;
  }

  .back p {
    width: 240px;
  }

  .front-icon {
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
   
  }
}

@media only screen and (max-width: 1200px) {
  .flip-container,
  .front,
  .back {
    width: 460px;
  }

  .hoverimage {
    width: 460px;
    height: 250px;
    float: left;
  }

  .back p {
    width: 420px;
  }

  .front-icon {
 left: 50%;
 transform: translate(-50%);
 -webkit-transform: translate(-50%);
 -moz-transform: translate(-50%);
 -ms-transform: translate(-50%);
 -o-transform: translate(-50%);
}



.front .name {
  font-size: 18px;
  line-height: 24px;
}

.back ul{
  font-size: 16px;

  }




}







@media only screen and (max-width: 1024px) {
  .flip-container,
  .front,
  .back {
    width: 450px;
  }

  .hoverimage {
    width: 450px;
    height: 250px;
    float: left;
  }

  .back p {
    width: 200px;
  }

  .front-icon {
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
   }

  .front .name {
    font-size: 18px;
  }
}

@media only screen and (max-width: 800px) {
  .flip-container,
  .front,
  .back {
    width: 100%;
    height: 300px;
  }

  .hoverimage {
    width: 100%;
    height: 300px;
    float: left;
  }

  .back p {
    width: 100%;
  }

  .front-icon {
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
   }


   .border334 {
    width: 90%;
    float: left;
    height: 80%;
    border-radius: 8px;
    margin: 5%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding-top: 10px;
  }


  .back ul {
    width: 100%;
    
}


}

@media only screen and (max-width: 600px) {
  .flip-container,
  .front,
  .back {
    width: 100%;
  }

  .hoverimage {
    width: 100%;
    height: 250px;
    float: left;
  }

  .back p {
    width: 200px;
  }

  .front-icon {
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
   }
}




@media only screen and (max-width: 430px) {
  .flip-container,
  .front,
  .back {
    width: 400px;
  }

  .hoverimage {
    width: 400px;
    height: 250px;
    float: left;
  }

  .back p {
    width: 350px;
  }

  .front-icon {
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
   }
}
