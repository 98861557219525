:root {
  --pitchColor: #faf0ee;
  --bgPitchColor: #faf0ee;
}

.about_section_home {
    background-color: var(--bgPitchColor);
    color: #777777;
    font-family: "Open Sans", sans-serif;
    position: relative;

    
.pen_img{
  position: absolute;
  right: 8%;
  bottom: 5%;
  background:  url(../../images/pen-img.png) no-repeat;
  background-size: cover;
  width: 200px;
  height: 200px;
  z-index: 100;
}

  }
  
  .about_section_home p {
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .paragraph_div p{
    font-weight: normal;
    margin-bottom: 10px;
  
  }
  
  
  
  .about_section_home ol {
    padding-left: 20px;
  }
  
  .about_section_home ol li {
    margin-bottom: 10px;
  }


  .testimonials_bg {
    background: var(--bgPitchColor) url(../../images/testi-main_bg.jpg);
    background-repeat: repeat-x;
    background-position: bottom;
    bottom: 0;
  }


  
.testimonials_bg .border {
  border: 1px solid #fae2df !important;
}

.testimonials_bg .border-5 {
  border-width: 15px !important;
  border-color: #fae2df;
}



.viewmore_btn {
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 15px;
  background: #ed1651;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.viewmore_btn:hover {
  background:var(--bgPitchColor);
  text-decoration: none;
  color: #777777 ;
}


.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}


.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.carousel-caption {
  position: absolute;
  right: 1%;
  bottom: 2rem;
  left: 3%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left;

  h1{
    font-size: min(max(24px, 4vw), 72px) !important;
  }



}

