
.bulletlist_contact ul {
    list-style-type: none;
    padding: 0 !important;
  }
  
  .bulletlist_contact li {
    padding-left: 0px;
    margin: 0px;
    list-style-type: none;
    line-height: 24px;
    margin-bottom: 0px;
    font-size: 19px;
  }

  .bulletlist_contact  h5{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    }


  .bulletlist_contact svg {
    max-width: 30px;
    margin-right: 10px;
    color: #d10000;
    margin-bottom: 10px;
  }
  
  .bulletlist_contact ul li a {
    text-decoration: none;
    color: #333;
    transition: all 0.5s ease;
  }
  
  .bulletlist_contact ul li a:hover {
    text-decoration: none;
    color: #d10000;
    transition: all 0.5s ease;
  }

  .stylefont{
    font-family: "Grey Qo", cursive;
    font-size: max(2.5vw, 30px) !important;

  }


  form label {
    color: #6e6e6e;
    }


    form input::placeholder {
        color: #999 !important;
        opacity: 1; /* Firefox */
      }

      input[type=date]:invalid::-webkit-datetime-edit {
        color: #999;
    }