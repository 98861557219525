

.card {
    transition: 250ms all ease-in-out;
    cursor: pointer;
}



  .card.bghover:hover {
    background-color: #7c51a1;
    transition: all 0.3s ease-in-out;
    color: #fff;

  }
  .card .icon_box span {
    display: table;
    margin: 0 auto 15px auto;
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .card .icon_box span:hover {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .card .icon_box span.wp-icon {
    background-size: cover;
    border-radius: 50%;
    background: #fff ;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center ;



  }



/*   
  .card:hover .icon_box span.wp-icon {
    background: #ed1651 ;
    background-size: cover;
  }


  .card:hover .icon_box span.wp-icon img {
    filter: invert(100%);
  } */