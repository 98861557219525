.footer {
  background-color: #f9cfca;
}

.footer p {
  color: #7c6866;
}

.footer ul {
  color: #7c6866;
}

.footer ul li {
  color: #7c6866;
  line-height: 30px;
}

.footer ul li a {
  color: #7c6866 !important; 
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.footer ul li a:hover {
  color: #ed1651 !important;
  transition: all 0.2s ease-in;
}

.footer h5 {
  display: inline-block;
  color: #7c6866;
}

.copy-right {
  color: #7c6866 !important; 
}

.copy-right a {
  color: #7c6866 !important; 
  transition: all 0.5s ease;
  text-decoration: none;
}

.copy-right a:hover {
  color: #e32222;
}

.mybulletlist2 ul {
  list-style-type: none;
  padding-left: 12px;
}

.mybulletlist2 li {
  padding-left: 0px;
  line-height: 24px !important;
}

.mybulletlist2 li:nth-of-type(1):before {
  font-family: "Font Awesome 5";
  font-weight: 900;
  content: "";
  color: #d10000;
}

.mybulletlist2 li:nth-of-type(2):before {
  font-family: "Font Awesome 5";
  font-weight: 900;
  content: "";
  color: #d10000;
}

.mybulletlist2 li:nth-of-type(3):before {
  font-family: "Font Awesome 5";
  font-weight: 900;
  content: "";
  color: #d10000;
}

.mybulletlist2 li:nth-of-type(4):before {
  font-family: "Font Awesome 5";
  font-weight: 900;
  content: "";
  color: #d10000;
}

.mybulletlist2 ul li a {
  text-decoration: none;
  color: #7c6a68;
  transition: all 0.5s ease;
}

.mybulletlist2 ul li a:hover {
  text-decoration: none;
  color: #d10000;
  transition: all 0.5s ease;
}



.rounded-social-buttons {
  text-align: left;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 18px;
  background-color: #f7acad;
}



.rounded-social-buttons .social-button.facebook {
  background: #f7acad;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #fff;
  background: #ed1651;
  border-color: #ed1651;
}

.rounded-social-buttons .social-button.youtube {
  background: #f7acad;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #fff;
  background: #ed1651;
  border-color: #ed1651;
}

.rounded-social-buttons .social-button.instagram {
  background: #f7acad;
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #fff;
  background: #ed1651;
  border-color: #ed1651;
}


.seo-list{
  display: flex;
  font-size: 13px;
  text-align: justify;

}




@media only screen and (max-width: 1680px) {

  .footer_logo{
    width: 200px;
  }
}




@media only screen and (max-width: 1480px) {

  .footer_logo{
    width: 200px;
  }
}



@media only screen and (max-width: 768px) {
.footer_logo{
  width: 240px;
}
.footer ul li {
  color: #7c6866;
  line-height: 24px;
  font-size: 15px;
}


.small {
  font-size: 13px;
}

}


@media only screen and (max-width: 600px) {

.footer_logo {
  width: 160px;
}

.footer h5 {
font-weight: 600 !important;
}

}

